<script setup lang="ts">
import { MainPage } from '@ocp-zmarta/content-sdk'
import useFormattedPageData from '@/composables/useFormattedPageData'
import App from '@/components/layout/App.vue'
import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      market: getServerState('market'),
      brand: getServerState('brand'),
      locale: getServerState('locale'),
      isMobile: getServerState('isMobile'),
    }

const { pageData, site, fragmentCustomQuery } = useFormattedPageData({ ctx })
</script>

<template>
  <app :page-data="pageData">
    <main-page
      v-if="pageData && site"
      :is-mobile="ctx?.isMobile"
      :locale="ctx?.locale"
      :page="pageData"
      :site="site"
      :fragment-custom-query="fragmentCustomQuery"
    />
  </app>
</template>

<style scoped lang="scss">
  :deep(.zg-text-section__body) {
    h1:not(:first-child) {
      font-size: 2.5rem !important;
      margin: rhythm(small) 0 rhythm(medium) 0 !important;

      @include mq(large) {
        font-size: 3.5rem !important;
      }
    }
  }
</style>
